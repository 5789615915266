.profile {
  padding-top: 30px;

  @media #{$mobile} {
    padding-top: 0;
  }

  .section-title {
    text-align: left;
    margin-top: 24px;
    margin-bottom: 0;
    padding-left: 15px;
  }
}
