.search-address-div {
  position: relative;
}

.search-address {
  position: absolute;
  z-index: 100;
  width: 100%;

  .search-address-content {
    text-align: start;
    border: 1px solid $grey;
    padding: 5px;
    background-color: $white;
  }
}

.btn-group {
  @media #{$mobile} {
    display: grid;
    justify-content: center;
  }
}
