.warehouse-form {
  padding-top: 30px;

  @media #{$mobile} {
    padding-top: 0;
  }

  .warehouse-header {
    h4 {
      margin: 0;
    }
  }
}

.modal-form {
  padding-top: 0;
}
