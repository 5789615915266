.Couriers {
  .CardBody {
    padding: 50px !important;

    .Title {
      padding-bottom: 16px;
    }
  }
}

.courier-form {
  padding-top: 30px;

  @media #{$mobile} {
    padding-top: 0;
  }

  .card-header {
    h4 {
      margin: 0;
    }
  }

  .courier-vehicle-list {
    h4 {
      font-size: 18px;
      margin: 15px 0;
      text-align: center;
    }

    .no-vehicles {
      text-align: center;
      padding: 30px;
      font-size: 16px;
    }
  }

  .section-title {
    text-align: left;
    margin-top: 60px;
    margin-bottom: 0;
    padding-left: 15px;

    @media #{$mobile} {
      margin-top: 16px;
    }
  }
}

.vehicle-form {
  .vehicle-type-select {
    margin-top: 27px;
    color: rgba(0, 0, 0, 0.87);

    .MuiInputLabel-root {
      color: #aaaaaa !important;
    }

    .MuiSelect-select,
    .MuiSelect-nativeInput {
      border-bottom-color: #aaaaaa !important;
    }

    label.Mui-focused {
      color: #aaaaaa !important;
    }

    .MuiInput-underline.Mui-focused:after {
      border-bottom: 2px solid #7fc15e;
    }
  }
}

.courier-vehicle-list {
  .Card-card-168 {
    box-shadow: none !important;
  }
}
