.Pagination {
  text-align: center;
  padding: 0;
  margin-top: 25px;
  width: 100%;

  .PageItem {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;

    .PageLink {
      color: #444;
      cursor: pointer;
      padding: 0 12px;
      line-height: 30px;
      outline: none;
      text-decoration: none;

      .ChevronIcon {
        margin-top: 2px;
      }
    }
  }
  .Active {
    background-color: #d7030c;
    color: #fff;
    width: auto;

    a {
      color: white !important;
    }
  }

  li:hover:not(.Active) {
    background-color: #ddd;
  }
}
