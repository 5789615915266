$red: #f11930;
$yellow: rgb(255, 193, 48);
$dark-sky-blue: #00838f;
$dark-blue: #026b9b;
$green: #50a750;
$light-green: #52ce52;
$dark-light-green: #439172;
$white: #ffffff;
$light-grey: #f5f5f5;
$grey: #bfbfbf;
$dark-grey: #707070;
$dark-light-grey: #54595f;
$not-black: #232323;

$plexo-green: #cecf00;
$plexo-light-green: #dedf4a;
$plexo-blue: #004790;
$plexo-red: #ce3535;
$plexo-yellow: #e89b1b;

$primary: #327cbc;
$primary-light: #e8f0f8;
$transparent-primary: rgba(241, 25, 48, 0.9);
$secondary: $dark-blue;
$transparent-yellow: rgba(255, 193, 48, 0.2);
$input-border: rgba(0, 0, 0, 0.23);
$black: #000000;

$mobile: 'only screen and (max-width: 768px)';
$desktop: 'only screen and (min-width: 769px)';

$header-height: 64px;
$header-content-height: 64px;
$white-line-height: 20px;
$footer-height: 60px;
$content-width: 1200px;
