@import './variables';
@import './auth';
@import './couriers';
@import './customer';
@import './deliveryOrders';
@import './home';
@import './input';
@import './pagination';
@import './profile';
@import './select';
@import './sidedrawer';
@import './specialInput';
@import './spinner';
@import './warehouses';

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

body {
  position: relative;
}

.main {
  font-family: 'Roboto Condensed', sans-serif;
  min-height: 100vh;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex-grow: 1;
    max-width: 100%;
    min-height: 600px;
    border-radius: 0.6rem;
    padding: 2rem;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out 100ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $white inset !important;
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  -webkit-text-fill-color: $black;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading-button {
  height: 16px;
}
